var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Jumbotron", { attrs: { title: "Telegram" } }, [
        _vm._v(" Verbindungen zu Telegram ")
      ]),
      _vm.ready
        ? _c(
            "CRow",
            [
              _c(
                "CCol",
                { attrs: { md: "8" } },
                [
                  _c("DataTable", {
                    attrs: {
                      items: _vm.telegramConnections.items,
                      fields: [
                        {
                          key: "telegramChatId",
                          label: "Chat Id",
                          sorter: false,
                          filter: false
                        },
                        {
                          key: "telegramUsername",
                          label: "Username",
                          sorter: false,
                          filter: false
                        },
                        {
                          key: "action",
                          label: "",
                          sorter: false,
                          filter: false
                        }
                      ],
                      hover: "",
                      loading: _vm.loading
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "telegramChatId",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("td", [
                                item.telegramChatId > 0
                                  ? _c("div", [
                                      _vm._v(_vm._s(item.telegramChatId))
                                    ])
                                  : _vm._e(),
                                item.telegramChatId == 0
                                  ? _c("div", [_vm._v("Nicht authentifiziert")])
                                  : _vm._e()
                              ])
                            ]
                          }
                        },
                        {
                          key: "action",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c(
                                "td",
                                [
                                  item.telegramChatId == 0
                                    ? _c(
                                        "CButton",
                                        {
                                          attrs: {
                                            color: "primary",
                                            href: _vm.telegramStartUrl
                                          }
                                        },
                                        [_vm._v(" Authentifizieren ")]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "CButton",
                                    {
                                      attrs: { color: "danger" },
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteTelegramChatId(
                                            item.id
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(" Löschen ")]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      213919590
                    )
                  }),
                  _vm.pages > 1
                    ? _c("CPagination", {
                        attrs: {
                          pages: _vm.pages,
                          activePage: _vm.currentPage
                        },
                        on: {
                          "update:activePage": function($event) {
                            _vm.currentPage = $event
                          },
                          "update:active-page": function($event) {
                            _vm.currentPage = $event
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "CCol",
                { attrs: { md: "4" } },
                [
                  _c(
                    "CreateCard",
                    {
                      attrs: {
                        loading: _vm.loading,
                        errorMessage: _vm.message,
                        title: _vm.$t(
                          "pages.host.HostTelegramModule.createTitle"
                        )
                      },
                      on: { handleSubmit: _vm.handleSubmit }
                    },
                    [
                      _c("ValidatedInput", {
                        attrs: {
                          translationKey: "telegramConnection.telegramUsername",
                          field: _vm.$v.telegramConnection.telegramUsername,
                          value:
                            _vm.$v.telegramConnection.telegramUsername.$model
                        },
                        on: {
                          "update:value": function($event) {
                            return _vm.$set(
                              _vm.$v.telegramConnection.telegramUsername,
                              "$model",
                              $event
                            )
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }